import $ from "jquery";
import {active} from "./const.js";

   // #js-singleContent内のh2タグを取得
   var headings = $('#js-singleContent h2');

   // リスト項目をクリア
   $('#js-singleContents').empty();

   // h2ごとにループ処理
   headings.each(function(index) {
       // 見出しのテキストを取得
       var headingText = $(this).text();

       // アンカーネームを付与
       var anchorName = 'anc-' + (index + 1);
       $(this).attr('id', anchorName);

       // リストにアンカーリンクを追加
       $('#js-singleContents').append(
           '<li><a href="#' + anchorName + '">' + headingText + '</a></li>'
       );
   });
