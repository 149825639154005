import $ from "jquery";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper(".js-homeSlider", {
  autoplay: {
    delay: 4500,
    disableOnInteraction: false,
  },
  effect: "fade",
  fadeEffect: {
    crossFade: true
  },
  loop: true,
  pagination: {
    el: ".js-homeSlider .swiper-pagination",
    renderBullet: (index, className) => {
      return '<span class="' + className + '"><svg class="circle" width="12" height="12" viewBox="0 0 12 12"><circle cx="6" cy="6" r="5"></svg></span>';
    },
  },
  slidesPerView: 1,
  spaceBetween: 0,
  speed: 800,
});
swiper.on('slideChange', function () {
  this.params.autoplay.delay = 3700;
});
