import $ from "jquery";
import {active} from "./const.js";
import {md} from "./const.js";

$('#js-hamburger').on('click', (event) => {
  $(event.currentTarget).toggleClass(active);
  if($(event.currentTarget).hasClass(active)) {
    $('body').css('overflow', 'hidden');
  } else {
    $('body').css('overflow', 'visible');
  }
  $("#js-headerNav").fadeToggle(300);
});

$('.js-navHas2nd').on('click', (event) => {
  if($(window).width() <= md) {
    $(event.currentTarget).toggleClass(active);
    $(event.currentTarget).next().slideToggle(300);
  }
});
