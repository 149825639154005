import $ from "jquery";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper(".js-slider", {
  breakpoints: {
    768: {
      navigation: {
        nextEl: ".js-sliderNext",
        prevEl: ".js-sliderPrev",
      },
      spaceBetween: 30,
    },
  },
  loop: true,
  slidesPerView: "auto",
  spaceBetween: 10,
});
