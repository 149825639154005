import $ from "jquery";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper(".js-slider2", {
  breakpoints: {
    768: {
      spaceBetween: 20,
    },
  },
  loop: true,
  slidesPerView: "auto",
  spaceBetween: 10,
});
