import $ from "jquery";
import {active} from "./const.js";

$('.js-newsTab').on('click', (event) => {
  $('.js-newsTab').removeClass(active);
  $(event.currentTarget).toggleClass(active);
  $('.js-newsTabContent').fadeOut(300);
  const index = $('.js-newsTab').index(event.currentTarget);
  $('.js-newsTabContent').eq(index).fadeIn(300);
});
