import $ from "jquery";
import {active} from "./const.js";

$('.js-tab').on('click', (event) => {
  $('.js-tab').removeClass(active);
  $(event.currentTarget).toggleClass(active);
  $('.js-tabContent').fadeOut(300);
  const index = $('.js-tab').index(event.currentTarget);
  $('.js-tabContent').eq(index).fadeIn(300);
});
