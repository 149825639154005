import $ from "jquery";
import {active} from "./const.js";
import {md} from "./const.js";

$(window).on('load resize', () => {
  const header = $('#js-header');
  const globalNav = $('#js-globalNav');
  const fixedClass = '-fixed';
  const showClass = '-show';
  const scrollYShow = 400;
  let headerHeight = header.outerHeight();

  const updateHeaderHeight = () => {
    headerHeight = header.outerHeight();
  };

  updateHeaderHeight();

  $(window).scroll(() => {
    const scrollTop = $(window).scrollTop();

    if(scrollTop > headerHeight) {
      header.addClass(fixedClass);
      $('body').addClass(fixedClass);
    } else {
      header.removeClass(fixedClass);
      $('body').removeClass(fixedClass);
    }

    if(scrollTop > scrollYShow) {
      header.addClass(showClass);
      globalNav.addClass(showClass);
    } else {
      header.removeClass(showClass);
      globalNav.removeClass(showClass);
    }
  });
});
